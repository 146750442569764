exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-dunav-crno-more-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/dunav-crno-more/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-dunav-crno-more-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-incoterms-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/incoterms/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-incoterms-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-kontakt-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/kontakt/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-kontakt-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-korisni-linkovi-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/korisni-linkovi/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-korisni-linkovi-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-korporativne-vrednosti-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/korporativne-vrednosti/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-korporativne-vrednosti-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-lucke-usluge-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/lucke-usluge/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-lucke-usluge-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-misija-i-vizija-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/misija-i-vizija/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-misija-i-vizija-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-morski-transport-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/morski-transport/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-morski-transport-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-nasa-flota-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/nasa-flota/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-nasa-flota-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-o-nama-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/o-nama/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-o-nama-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-osiguranje-robe-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/osiguranje-robe/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-osiguranje-robe-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-recni-transport-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/recni-transport/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-recni-transport-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-trgovina-robom-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/uroszivaljevic/Desktop/freelance/donau-partner/donau-partner-web/pages/trgovina-robom/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-users-uroszivaljevic-desktop-freelance-donau-partner-donau-partner-web-pages-trgovina-robom-index-mdx" */)
}

